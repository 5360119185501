import { FormControl, FormHelperText, Input, InputLabel } from "@mui/material";

export default function FormInput ({ label, id, helperText, onChange, value }) {
  return (
    <FormControl sx={{ width: '100%' }}>
      <InputLabel htmlFor={id}>{label || id}</InputLabel>
      <Input id={id} aria-describedby={`${id}-text}`} onChange={onChange} value={value} sx={{ width: '100%' }} />
      {helperText && <FormHelperText id={`${id}-text}`}>
        {helperText}
      </FormHelperText>}
    </FormControl>
  );
}
