import Mustache from 'mustache';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
// import fs from 'fs';
// import template from '../Podpis.html';

export default function Podpis({ id, jmeno, funkce, oddeleni, ustav, fakulta, telefon, mobil, email, url, onData }) {

  const [rendered, setRendered] = useState('');
  
  useEffect(() => {
    const load = async () => {
      const telefons = `${telefon && `T: ${telefon}`}${telefon && mobil && `, `}${mobil && `M: ${mobil}`}`;
      const data = { id, jmeno, funkce, oddeleni, ustav, fakulta, 
        telefons,
        telefon,
        mobil,
        email, url
      };
      const resp = await fetch('Podpis.html');
      const template = await resp.text();
      console.log("Template:", template);
      const r = Mustache.render(template, data);
      setRendered(r);
      onData(r);

    };
    load();
  })
  // const template = fs.readFileSync('src/podpis.html');




  const copyHtml = () => {
    const el = document.getElementById(id || 'podpis');
    console.log(el.innerHTML);
    const range = document.createRange();
    range.selectNodeContents(el);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy');
    selection.removeAllRanges();
    if (typeof onData === 'function') {
      onData(el.innerHTML);
    }
  };

  return (
    <div id={id || 'podpis'} onClick={copyHtml}>
      {/* <textares>{rendered}</textares> */}
      {parse(rendered)}
    </div>
  )
}