import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import FormInput from './components/FormInput';
import Podpis from './components/PodpisTemplate';
import PodpisReact from './components/PodpisReact';
import { Card, CardActionArea, CardContent, CardHeader, TextareaAutosize, TextField } from '@mui/material';


export default function App() {
  const [htmlData, setHtmlData] = React.useState();

  const [data, setData] = React.useState({
    "jmeno": "Ing. Jmeno Prijmeni, Ph.D. ",
    "funkce": "Odborný asistent",
    "oddeleni": "Studijní oddělení",
    "ustav": "Ateliér prostorové tvorby",
    "fakulta": "Fakulta výtvarných umění",
    "telefon": "+420 54114 xxxx",
    "mobil": "",
    "email": "doplnit@favu.vut.cz",
    "url": "https://www.favu.vut.cz"
  });



  const handleChange = (event) => {
    console.log('Change', event.target.id, event.target.value, data);
    setData({ ...data, [event.target.id]: event.target.value });
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          FaVU emal podpis
        </Typography>
        <Card>
          <CardContent>
            <Box sx={{ py: 2 }}>
              <FormInput id="jmeno" label="Jméno" XhelperText="Jmeno" onChange={handleChange} value={data.jmeno} />
            </Box>
            <Box sx={{ py: 2 }}>
              <FormInput id="funkce" label="Funkce" onChange={handleChange} value={data.funkce} />
            </Box>
            <Box sx={{ py: 2 }}>
              <FormInput id="oddeleni" label="Oddělení součásti" onChange={handleChange} value={data.oddeleni} />
            </Box>
            {/* <Box sx={{ py: 2 }}>
              <FormInput id="ustav" label="Ústav" onChange={handleChange} value={data.ustav} />
            </Box> */}
            {/* <Box sx={{ py: 2 }}>
              <FormInput id="fakulta" label="Fakulta" onChange={handleChange} value={data.fakulta} />
            </Box> */}
            <Box sx={{ py: 2 }}>
              <FormInput id="telefon" label="Telefon" onChange={handleChange} value={data.telefon} />
            </Box>
            <Box sx={{ py: 2 }}>
              <FormInput id="mobil" label="Mobil" onChange={handleChange} value={data.mobil} />
            </Box>
            <Box sx={{ py: 2 }}>
              <FormInput id="email" label="Email" onChange={handleChange} value={data.email} />
            </Box>
            <Box sx={{ py: 2 }}>
              <FormInput id="url" label="Url" onChange={handleChange} value={data.url} />
            </Box>
          </CardContent>
        </Card>

        <Card sx={{ mt: 2 }}>
          {/* <CardActionArea component="span"> */}
          <CardHeader title="Náhled" />
          <CardContent>
            Kliknutím zkopírujete html kód do schránky
          </CardContent>
            <CardContent
              sx={{
                p: 2,
                cursor: 'pointer',
                // background: "#eee",
                border: 2,
                borderColor: 'secondary.main',
                '&:hover': {
                  // background: "#fff",
                  borderColor: 'primary.main',
                  border: 2,
                },
              }}
            >
              {/* <pre>{JSON.stringify(data, null, 4)}</pre> */}
              <Podpis {...data} onData={setHtmlData} />
            </CardContent>
          {/* </CardActionArea> */}
        </Card>

        <Card sx={{ mt: 2 }}>
        <CardHeader title="Html" />
          <CardContent>
            <TextField multiline value={htmlData} sx={{ width: '100%' }} />
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
